import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'
import { ftthI18nMessages } from './messages'

type Props = {
	children: React.ReactNode
}

const NewUfinet18nProvider = Ufineti18nProvider as any

export const FTTHi18nProvider = ({ children }: Props) => {
	return (
		<NewUfinet18nProvider>
			<I18nProvider messagesByLanguage={ftthI18nMessages}>{children}</I18nProvider>
		</NewUfinet18nProvider>
	)
}
