import { FC, useState, useEffect, useCallback, useContext } from 'react'
import {
	Table,
	UfinetBox,
	UfinetSectionBox,
	formatTableFilters,
	UfinetButton,
	MultipleDeleter,
	UfinetModal,
} from 'ufinet-web-components'
import { useModal } from 'ufinet-web-functions'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faDownload, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { DataTablePFSEvent } from 'primereact/datatable'
import { AuthContext } from 'ufinet-web-functions'
import { useQuery, useMutation } from '@tanstack/react-query'
import BulkUpload from 'components/modals/BulkUpload'
import NewAndEditModal from 'components/modals/NewAndEditModal'
import { toast } from 'react-toastify'
import { getRows, getExcelFile, deleteRows } from 'api/requests'
import { Translation } from 'utils/translation/Translation'
import { getPermissionsByPathname } from 'utils/permissions/getPermissionsByPathname'
const NewUfinetBox = UfinetBox as any

type FormattedUserResponse = any

const generateColsData = (inputs: any) => {
	return (
		inputs
			// Delete columns with dontShowInTable = true
			.filter((input: input) => input.dontShowInTableAndModal !== true)
			.map((input: input) => {
				return {
					field: input.columnName,
					header: Translation(input.columnLabelIntl),
					width: input.columnWidth,
					nonFilterable: false,
					type: 'text',
					filterType: 'text',
				}
			})
	)
}

interface input {
	columnName: string
	columnLabelIntl: string
	columnWidth: string
	columnType: string
	requiredFieldErrorText: string
	dontShowInTableAndModal?: boolean
}

type StandardTableWithButtonsProps = {
	inputs: input[]
	pathName: string
	reactQueryKeyName: string
	apiRoutePath: string
	downloadedFileName: string
	addModalTittleIntl: string
	editModalTittleIntl: string
	onlyViewMode: boolean | undefined
	menuTittle: string
}

const StandardTableWithButtons: FC<StandardTableWithButtonsProps> = ({
	inputs,
	pathName,
	reactQueryKeyName,
	apiRoutePath,
	downloadedFileName,
	addModalTittleIntl,
	editModalTittleIntl,
	onlyViewMode,
	menuTittle,
}) => {
	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions: any = getPermissionsByPathname(pathName, roles)
	const cols = generateColsData(inputs)

	const initialFilterData = {
		pageNumber: 0,
		rowsPerPage: 20,
	}

	const [selectedValues, setSelectedValues] = useState<FormattedUserResponse[]>([])
	const [paging, setPaging] = useState<any>()
	const [filterData, setFilterData] = useState<{}>(initialFilterData)

	const [modalRow, setModalRow] = useState<any>({})

	const query = useQuery({
		queryKey: [reactQueryKeyName, filterData],
		queryFn: () => getRows(filterData, apiRoutePath),
	})
	const { data, isPending, isError, error } = query

	const totalExcelquery = useQuery({
		queryKey: ['downloadTotalExcel'],
		queryFn: () =>
			getExcelFile(
				{ ...filterData, rowsPerPage: JSON.parse(data?.headers['x-pagination']).totalCount || 999999999999999 },
				apiRoutePath,
				downloadedFileName
			),
	})

	const { isPending: isTotalExcelPending, isError: isExcelError, error: excelError } = totalExcelquery

	// Logout user if error 401
	const unauthorizedError = Translation('AUTH.UNAUTHORIZED')
	useEffect(() => {
		if (
			((isError || isExcelError) && (error as any)?.response?.status === 401) ||
			(excelError as any)?.response?.status === 401
		) {
			toast.error(unauthorizedError)
			authData.logout()
		}
	}, [isError, error, isExcelError, excelError, authData])

	// Mutation to handle delete rows
	const genericErrorMessage = Translation('MODALS.GENERICERROR')
	const successMessage = Translation('PAGE.ROWSDELETED')
	const mutation = useMutation({
		mutationFn: (values: any) => deleteRows(values, apiRoutePath),
		onSuccess: () => {
			toast.success(successMessage)
		},
		onError: (error: any) => {
			if (error.response.status === 401) {
				toast.error(unauthorizedError)
				authData.logout()
				return
			}
			toast.error(
				error?.response?.data?.message ||
					error?.response?.data?.detail ||
					error?.response?.data?.title ||
					genericErrorMessage
			)
		},
	})

	const { mutateAsync: deleteRowsMutation } = mutation

	const [newAndEditModal, showNewAndEditModal, hideNewAndEditModal] = useModal()
	const [bulkUploadModal, showBulkUploadModal, hideBulkUploadModal] = useModal()

	useEffect(() => {
		if (data) {
			const parsePagination = JSON.parse(data?.headers['x-pagination'])
			const formattedPagination = {
				totalElements: parsePagination.totalCount,
				totalPages: parsePagination.totalPages,
				pageSize: parsePagination.rowsPerPage,
				pageNumber: parsePagination.currentPage,
			}
			setPaging(formattedPagination)
		}
	}, [data])

	useEffect(() => {
		// Clean component on unmount (changes pathname)
		return () => {
			setSelectedValues([])
			setPaging(undefined)
			setFilterData(initialFilterData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathName])

	const onFilterClear = (): void => {
		setFilterData(initialFilterData)
	}

	const onFilter = (e: DataTablePFSEvent): void => {
		const formattedFilters = formatTableFilters(e)
		setFilterData(formattedFilters)
	}

	const onPage = (e: DataTablePFSEvent): void => {
		setFilterData({ ...filterData, pageNumber: e.page, rowsPerPage: e.rows })
	}

	const onSort = (e: DataTablePFSEvent): void => {
		setFilterData({
			...filterData,
			sortField: e.sortField,
			sortOrder: e.sortOrder === 1 ? 'ASC' : e.sortOrder === -1 ? 'DESC' : null,
		})
	}

	const getHeaderButtons = () => {
		return (
			<>
				{!onlyViewMode && (
					<>
						{permissions.canDelete && selectedValues.length > 0 && (
							<MultipleDeleter
								setSelectedValues={setSelectedValues}
								selectedValues={selectedValues!}
								deleteEndpoint={() => deleteRowsMutation(selectedValues)}
								search={query.refetch}
							/>
						)}

						{permissions.canWrite && (
							<>
								<UfinetButton
									className="me-3"
									icon={faPlus}
									onClick={() => showNewAndEditModal()}
									content={Translation('PAGE.NEW')}
								/>
								{pathName !== 'Operator' && (
									<UfinetButton
										className="me-3"
										icon={faPlus}
										onClick={() => showBulkUploadModal()}
										content={Translation('PAGE.MASSIVE')}
									/>
								)}
							</>
						)}
					</>
				)}
				<UfinetButton
					className="me-3"
					icon={faFileExcel}
					onClick={() => getExcelFile(filterData, apiRoutePath, downloadedFileName)}
					content={Translation('PAGE.DOWNLOADTEMPLATE')}
				/>
				<UfinetButton
					className="me-3"
					icon={faDownload}
					isDisabled={isTotalExcelPending}
					onClick={() => totalExcelquery.refetch()}
					content={isTotalExcelPending ? Translation('LOADING_DOTS') : Translation('PAGE.DOWNLOADREGISTRY')}
				/>
			</>
		)
	}

	function getEditPencilBodyTemplate<T>(rowData: T, onClick: () => void) {
		if (pathName === 'PolygonOptimization') {
			return <></>
		}
		return (
			<>
				{!onlyViewMode && (
					<div>
						<i className="pi pi-pencil edit-pencil" onClick={onClick} />
					</div>
				)}
			</>
		)
	}

	const onSelectionChange = useCallback((values: FormattedUserResponse[]) => setSelectedValues(values), [])

	return (
		<>
			<NewUfinetBox>
				<UfinetSectionBox>
					<h2 className="fs-3 fw-bolder text-dark position-relative">{Translation(menuTittle)}</h2>
					<Table
						key={apiRoutePath}
						dataKey="uniqueId"
						selectedValues={!onlyViewMode ? selectedValues : []}
						onSelectionChange={permissions.canDelete && !onlyViewMode ? onSelectionChange : undefined}
						cols={cols}
						content={data?.data.map((item: FormData) => ({
							...item,
						}))}
						headerButtons={getHeaderButtons()}
						actionBodyTemplate={
							!onlyViewMode && permissions.canUpdate
								? (row: string) =>
										getEditPencilBodyTemplate(row, () => {
											setModalRow(row)
											showNewAndEditModal()
										})
								: undefined
						}
						emptyMessage={isPending ? Translation('LOADING_DOTS') : undefined}
						onFilterClear={onFilterClear}
						onSort={onSort}
						lazySettings={
							paging && {
								...paging,
								loading: isPending,
								onFilter,
								onPage,
								onSort,
							}
						}
					/>

					<UfinetModal
						size="lg"
						show={newAndEditModal}
						handleClose={() => {
							hideNewAndEditModal()
							setModalRow({})
						}}
						title={
							Object.keys(modalRow).length
								? Translation(editModalTittleIntl) ?? ''
								: Translation(addModalTittleIntl) ?? ''
						}
					>
						<NewAndEditModal
							hideModal={hideNewAndEditModal}
							apiRoutePath={apiRoutePath}
							row={modalRow}
							successFn={() => setModalRow({})}
							inputs={inputs}
							toInvalidateQuery={reactQueryKeyName}
							onSuccessAddMessage={Translation('MODALS.ADDSUCCESS') || ''}
							onSuccessEditMessage={Translation('MODALS.EDITSUCCESS') || ''}
							onErrorGenericMessage={Translation('MODALS.GENERICERROR') || ''}
						/>
					</UfinetModal>
					<UfinetModal
						size="lg"
						show={bulkUploadModal}
						handleClose={hideBulkUploadModal}
						title={Translation('PAGE.MASSIVE') || ''}
					>
						<BulkUpload
							hideModal={hideBulkUploadModal}
							apiRoutePath={apiRoutePath}
							toInvalidateQuery={reactQueryKeyName}
						/>
					</UfinetModal>
				</UfinetSectionBox>
			</NewUfinetBox>
		</>
	)
}

export default StandardTableWithButtons
