import { createRoot } from 'react-dom/client'
import 'ufinet-web-components/dist/index.css'
import { AppRoutes } from 'routes/AppRoutes'
import { AuthProvider } from 'ufinet-web-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FTTHi18nProvider } from 'utils/translation/i18n/FTTHi18nProvider'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
})

const domNode = document.getElementById('root')
if (domNode) {
	const root = createRoot(domNode)
	root.render(
		<>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<FTTHi18nProvider>
						<AppRoutes />
					</FTTHi18nProvider>
				</AuthProvider>
			</QueryClientProvider>
		</>
	)
}
