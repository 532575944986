import moment from 'moment'

export const transformToLocalTime = (date: any) => {
	if (!date) {
		return ''
	}

	// convert inputDate to UTC date string
	const inputDate = new Date(date)

	// Check if the input date is valid
	if (isNaN(inputDate.getTime())) {
		return 'Invalid date format'
	}

	// get user time zone
	const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone

	// using moment.js, convert the date to local time
	const localTime = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
	return `${localTime} ${timeZoneString}`
}
