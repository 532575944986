import { fetchGET2, fetchGETBlob2, fetchPOST2, fetchPATCH2, fetchDELETE2 } from 'api/https/https'
import { transformToLocalTime } from 'utils/time/transformToLocalTime'
import { v4 } from 'uuid'

interface Params {
	[key: string]: string
}

const normalizeParams = (params: Params) => {
	const serchParms = new URLSearchParams()

	//If there's no pageNumber and rowsPerPage, append them
	if (!params.pageNumber && !params.rowsPerPage) {
		serchParms.append('pageNumber', '0')
		serchParms.append('rowsPerPage', '20')
	}

	for (const [key, value] of Object.entries(params)) {
		if (value !== null) serchParms.append(key, value)
	}

	return serchParms.toString()
}

// Get all rows
const getRows = async (params: Params, route: string) => {
	//fetch data and the assigne uniqueId property to each row in data.data
	const data = await fetchGET2(`${route}?${normalizeParams(params)}`)

	if (data) {
		data.data.forEach((row: any) => {
			row.uniqueId = v4()
			row.fecha = route === 'Bitacora' ? transformToLocalTime(row.fecha) : row.fecha
		})
	}

	return data
}

// Download Excel file
const getExcelFile = async (params: Params, route: string, fileName: string) => {
	return await fetchGETBlob2(`${route}/file?${normalizeParams(params)}`, null, fileName)
}

// Add a new row
const addRow = async (data: any, route: string) => {
	if (route === 'Operator') {
		const operatorData: any = {
			operatorCode: data.operatorCode,
			name: data.name,
			physicalNetwork: '',
			operatorPhysicalNetwork: [
				{
					physicalNetworkId: data.physicalNetwork,
					operatorCode: data.operatorCode,
				},
			],
		}
		return await fetchPOST2(`${route}`, operatorData)
	}

	// If ctO_COVERAGE_ID is included, remove it from the data object. Its not requested in the API
	if (data.ctO_COVERAGE_ID === '') {
		delete data.ctO_COVERAGE_ID
	}

	// If polygon_Coverage_Id is included, remove it from the data object. Its not requested in the API
	if (data.polygon_Coverage_Id === '') {
		delete data.polygon_Coverage_Id
	}

	// If route is for GPON Treee and 'id' is included, remove it from the data object. Its not requested in the API
	if (route === 'GponTree' && data.id === '') {
		delete data.id
	}

	// If olT_REGISTRY is included, remove it from the data object. Its not requested in the API
	if (data.olT_REGISTRY === '') {
		delete data.olT_REGISTRY
	}

	if (route === 'VlanInventory') {
		data.vlan = parseInt(data.vlan)
	}

	return await fetchPOST2(`${route}`, data)
}

// Update a row
const editRow = async (data: any, route: string) => {
	if (route === 'Operator') {
		const operatorData: any = {
			operatorCode: data.operatorCode,
			name: data.name,
			physicalNetwork: '',
			operatorPhysicalNetwork: [
				{
					physicalNetworkId: data.physicalNetwork,
					operatorCode: data.operatorCode,
				},
			],
		}
		return await fetchPATCH2(`${route}`, operatorData)
	}

	if (route === 'VlanInventory') {
		data.vlan = parseInt(data.vlan)
	}
	return await fetchPATCH2(`${route}`, data)
}

// Add batch by xls file
const postFile = async (data: any, route: string) => {
	const formdata = new FormData()
	formdata.append('file', data?.file)
	return await fetchPOST2(`${route}/upload`, formdata)
}

// Delete Ids
const deleteRows = async (data: any, route: string) => {
	// Remove uniqueId property from data
	data.forEach((row: any) => {
		delete row.uniqueId
	})

	return await fetchDELETE2(`${route}`, data)
}

// Get selectable fields options for Operator and normalize them
const getOperatorOptions = async () => {
	const operators = await fetchGET2(`${'/Operator?pageNumber=0&rowsPerPage=100000000'}`)
	const options: any = []
	operators.data.forEach((item: any) => {
		options.push({
			value: item.operatorCode,
			label: item.operatorCode,
		})
	})
	return options
}

const getPhysicalNetworkForOperatorOptions = async () => {
	const physicalNetworks = await fetchGET2(`${'/Operator/physicalNetforkforoperator'}`)
	const options: any = []
	physicalNetworks.data.forEach((item: any) => {
		options.push({
			value: item.description,
			label: item.description,
		})
	})
	return options
}

const getStatusOptions = async (route: string) => {
	const status = await fetchGET2(route)
	const options: any = []
	status.data.forEach((item: any) => {
		options.push({
			value: item.description,
			label: item.description,
		})
	})
	return options
}

export {
	getRows,
	getExcelFile,
	addRow,
	editRow,
	postFile,
	deleteRows,
	getOperatorOptions,
	getStatusOptions,
	getPhysicalNetworkForOperatorOptions,
}
