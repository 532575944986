import { AsideDefault } from 'layout/aside/AsideDefault'
import { AsideMobile } from 'layout/aside/AsideMobile'
import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { MasterLayout, WelcomePage, AuthRoute } from 'ufinet-web-components'
import { Translation } from 'utils/translation/Translation'

import StandardTableWithButtons from 'views/StandardTableWithButtons'
import { pagesAndInputs } from 'pagesAndInputs'

export const PATH_HOME = ''

//routerMap: traduccion de cada elemento del la url que se quiere mostrar en el menu de forma individual
//si tenemos un PATH_X="ejemplo/ejemplo2", en nuestro routerMap metemos una clave para ejemplo y otra para ejemplo2
const routePermissionMap = new Map()

pagesAndInputs.forEach((item) => {
	const pathConstant = `${item.pathName.toUpperCase()}`

	routePermissionMap.set(pathConstant, [item.viewPermissions])
})

const PrivateRoutes: FC = () => {
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routePermissionMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				{/* Welcome Page */}
				<Route path={PATH_HOME} element={<WelcomePage appName={Translation('MENU.FTTH.TITTLE')} />} />
				{pagesAndInputs.map((item) => (
					<Route
						key={item.pathName}
						path={item.pathName}
						element={
							<AuthRoute authorizedRoles={routePermissionMap.get(`${item.pathName.toUpperCase()}`)!}>
								<StandardTableWithButtons
									inputs={item.inputs}
									pathName={item.pathName}
									reactQueryKeyName={item.reactQueryKeyName}
									apiRoutePath={item.apiRoutePath}
									downloadedFileName={item.downloadedFileName}
									addModalTittleIntl={item.addModalTittleIntl}
									editModalTittleIntl={item.editModalTittleIntl}
									onlyViewMode={item.onlyViewMode || false}
									menuTittle={item.menuTitle}
								/>
							</AuthRoute>
						}
					/>
				))}

				{/* Default route path */}
				<Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
