import Layout from 'layout/Layout'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthPage, VerificationPage } from 'ufinet-web-components'
import { AuthContext, authService, AuthStatus } from 'ufinet-web-functions'
import { PrivateRoutes } from './protected/PrivateRoutes'
import { Translation } from 'utils/translation/Translation'

export const PATH_AUTH = 'auth'

const AppRoutes: FC = () => {
	const { status, setStatus, token, setToken, logout, clear } = useContext(AuthContext)

	const [isSSOLoggingIn, setIsSSOLoggingIn] = useState<boolean>()

	useEffect(() => {
		authService.setUpBroadcastChannel({ onLogoutReceived: clear })

		setStatus(AuthStatus.VERIFYING)
		beginAuthentication()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (status === AuthStatus.LOGGED_OUT) return

		isSSOLoggingIn === false && checkAuthentication()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSSOLoggingIn, token])

	const beginAuthentication = () => {
		setIsSSOLoggingIn(true)
		authService
			.initSSOLogin(setToken)
			.catch((e) => {
				console.warn('SSO login attempt failed: ', e)
				if (status !== AuthStatus.LOGGED_IN) toast.error(Translation('AUTH.LOGIN.DOWN'))
			})
			.finally(() => setIsSSOLoggingIn(false))
	}

	const checkAuthentication = useCallback(() => {
		if (!token) {
			console.log('No token found, logging out')
			logout()
			return
		}

		authService
			.isAuthenticated(setToken, token)
			.then((userData) => {
				setStatus(AuthStatus.LOGGED_IN, userData)
			})
			.catch((e) => {
				console.log('Authentication failed, logging out: ', e)
				toast.dismiss()
				toast.error(Translation('AUTH.SESSION.EXPIRED'))
				logout()
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token])

	// Periodically refresh token if user stays in page
	const refreshToken = useCallback(() => {
		if (status === AuthStatus.LOGGED_IN)
			authService.refreshToken(setToken).catch((e) => {
				console.log('Token refresh failed, logging out: ', e)
				toast.error(Translation('AUTH.SESSION.EXPIRED'))
				logout()
			})
	}, [status, setToken, logout])

	useEffect(() => {
		const refreshInterval = setInterval(refreshToken, 15 * 60 * 1000)

		return () => {
			clearInterval(refreshInterval)
		}
	}, [refreshToken])

	return (
		<BrowserRouter>
			<Routes>
				<Route element={<Layout />}>
					{status === AuthStatus.LOGGED_IN ? (
						<Route path="/*" element={<PrivateRoutes />} />
					) : status === AuthStatus.VERIFYING ? (
						<Route path="/*" element={<VerificationPage />} />
					) : (
						<>
							<Route path={`${PATH_AUTH}/*`} element={<AuthPage />} />
							<Route path="*" element={<Navigate to={PATH_AUTH} />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export { AppRoutes }
