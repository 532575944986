import { FC } from 'react'
import { Link } from 'react-router-dom'
import { AsideMenuMain } from './AsideMenuMain'
import { Translation } from 'utils/translation/Translation'

import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// The KT aside toggle is being troublesome, for now,
// we'll check and toggle it manually manipulating body attributes.
const bodyAsideToggleAttribute = 'data-kt-aside-minimize'
export const toggleSidebar = () => {
	const minimized = document.body.getAttribute(bodyAsideToggleAttribute)
	const newMinimizedValue = minimized === 'on' ? 'off' : 'on'
	document.body.setAttribute(bodyAsideToggleAttribute, newMinimizedValue)
}

const AsideMobile: FC = () => {
	return (
		<div
			className="menu menu-sub menu-sub-slide menu-column w-250px w-lg-325px"
			data-kt-menu="true"
			id="kt_aside_mobile"
		>
			{/* begin::Brand */}
			<div className="aside-logo flex-column-auto justify-content-center" id="kt_aside_logo">
				{/* begin::Logo */}
				<Link to="/">
					<div className="d-flex align-items-center justify-content-center">
						<FontAwesomeIcon className="logo-minimize color-primary" size="3x" icon={faUsers} />
						<h1 className="logo fw-boldest my-auto ms-4 text-primary">{Translation('MENU.FTTH.TITTLE')}</h1>
						{process.env.REACT_APP_ENV !== 'prod' && (
							<h2
								style={{
									fontSize: '1.5rem',
									color: 'white',
									fontWeight: 'bold',
									backgroundColor: 'red',
									padding: '5px',
								}}
							>
								{process.env.REACT_APP_ENV?.toUpperCase()}
							</h2>
						)}
					</div>
				</Link>
				{/* end::Logo */}
			</div>
			{/* end::Brand */}

			{/* begin::Aside menu */}
			<div className="aside-menu flex-column-fluid">
				<div className="menu menu-column">
					<AsideMenuMain />
				</div>
			</div>
			{/* end::Aside menu */}
		</div>
	)
}

export { AsideMobile }
