/* eslint-disable react/jsx-no-target-blank */
import { FC, useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faDrawPolygon, faBox, faEthernet } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'
import { pagesAndInputs } from 'pagesAndInputs'
import { Translation } from 'utils/translation/Translation'

enum AsideMenu {
	CTO,
	PFS,
	POLYGON,
	NONE,
}

const AsideMenuMain: FC = () => {
	const location = useLocation()

	const [active, setActive] = useState(AsideMenu.NONE)
	const isCtoActive = () => active === AsideMenu.CTO
	const isPfsActive = () => active === AsideMenu.PFS
	const isPolygonActive = () => active === AsideMenu.POLYGON
	const activeMenu = (menu: AsideMenu) => () => setActive(menu)

	const { userData } = useContext(AuthContext)

	const roles = userData?.authorities || []
	const ftthPermissions = [
		pagesAndInputs
			.filter((item) => item.menuGroupedBy === 'CTO')
			.map((item) => item.menuPermissions(roles).canRead === true),
		pagesAndInputs
			.filter((item) => item.menuGroupedBy === 'POLYGON')
			.map((item) => item.menuPermissions(roles).canRead === true),
		pagesAndInputs
			.filter((item) => item.menuGroupedBy === 'PFS')
			.map((item) => item.menuPermissions(roles).canRead === true),
	]

	return (
		<>
			{ftthPermissions[0].some((val) => val === true) && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faBox} className="fs-2x" />}
					title={Translation('MENU.CTO.TITTLE')}
					active={isCtoActive()}
				>
					{pagesAndInputs
						.filter((item) => item.menuGroupedBy === 'CTO' && item.menuPermissions(roles).canRead)
						.map((item) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.pathName}
								to={item.pathName}
								icon={faCircle}
								title={Translation(item.menuTitle)}
								onClick={activeMenu(AsideMenu.CTO)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
			{ftthPermissions[1].some((val) => val === true) && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faEthernet} className="fs-2x" />}
					title={Translation('MENU.PFS.TITTLE')}
					active={isPfsActive()}
				>
					{pagesAndInputs
						.filter((item) => item.menuGroupedBy === 'PFS')
						.map((item) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.pathName}
								to={item.pathName}
								icon={faCircle}
								title={Translation(item.menuTitle)}
								onClick={activeMenu(AsideMenu.PFS)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
			{ftthPermissions[2].some((val) => val === true) && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faDrawPolygon} className="fs-2x" />}
					title={Translation('MENU.POLYGON.TITTLE')}
					active={isPolygonActive()}
				>
					{pagesAndInputs
						.filter((item) => item.menuGroupedBy === 'POLYGON')
						.map((item) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.pathName}
								to={item.pathName}
								icon={faCircle}
								title={Translation(item.menuTitle)}
								onClick={activeMenu(AsideMenu.POLYGON)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
			<>
				{pagesAndInputs
					.filter((item) => !item.menuGroupedBy && item.menuPermissions(roles).canRead)
					.map((item) => (
						<AsideMenuItem
							routerLocation={location}
							key={item.pathName}
							to={item.pathName}
							icon={faCircle}
							title={Translation(item.menuTitle)}
							onClick={activeMenu(AsideMenu.NONE)}
						/>
					))}
			</>
		</>
	)
}

export { AsideMenuMain }
