import { useCallback, FC } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone } from 'ufinet-web-components'
import { onFormikChanges, IFileUploadData } from 'ufinet-web-functions'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Translation } from 'utils/translation/Translation'
import * as Yup from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { postFile } from 'api/requests'

interface BulkUploadProps {
	hideModal: () => void
	toInvalidateQuery: string
	apiRoutePath: string
}

interface File {
	file: IFileUploadData
}

const BulkUpload: FC<BulkUploadProps> = ({ hideModal, toInvalidateQuery, apiRoutePath }: BulkUploadProps) => {
	const queryClient = useQueryClient()
	const successMessage = Translation('MODALS.MASSIVEUPLOADSUCCESS')
	const genericErrorMessage = Translation('MODALS.GENERICERROR')

	const fileUploadMutation = useMutation({
		mutationFn: (values) => postFile(values, apiRoutePath),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [toInvalidateQuery] })
			toast.success(successMessage)
			hideModal()
		},
		onError: (error: any) => {
			toast.error(error?.response?.data?.message || genericErrorMessage)
		},
	})

	const { mutateAsync: addByFileUpload, isPending } = fileUploadMutation

	const onChange = useCallback(onFormikChanges, [])

	const dataFormSchema = Yup.object().shape({
		file: Yup.mixed()
			.test('file required', Translation('ERROR.REQUIRED'), (value: any) => value?.size > 0)
			.test('file type', Translation('ERROR.FILE.EXTENSION'), (value: any) => {
				const fileExtension = value?.path?.split('.').pop()
				return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
			}),
	})

	const formik = useFormik<File>({
		initialValues: {
			file: {
				path: '',
				size: 0,
			},
		},
		validationSchema: dataFormSchema,
		onSubmit: (values: any) => addByFileUpload(values),
		validateOnChange: false,
		validateOnBlur: false,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<div>
				<div className="row">
					<div className="col-12">
						<UfinetDropzone
							isDisabled={false}
							onChange={onChange(formik, 'file')}
							value={formik.values.file}
							error={formik.errors.file}
							text={Translation('DROPZONE.UPLOAD_EXCEL.TITLE')}
							accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
						/>
					</div>
				</div>

				<div className="row py-10">
					<div className="col-12">
						<UfinetButton
							type="submit"
							className="w-100"
							content={isPending ? Translation('LOADING') : Translation('SAVE')}
							icon={faSave}
							isDisabled={isPending}
						/>
					</div>
				</div>
			</div>
		</form>
	)
}

export default BulkUpload
