import { i18nMessages } from 'ufinet-web-components'
import ftthEnMessages from './en.json'
import ftthEsMessages from './es.json'
import ftthPtMessages from './pt.json'

export const ftthI18nMessages: i18nMessages = {
	en: {
		...ftthEnMessages,
	},
	es: {
		...ftthEsMessages,
	},
	pt: {
		...ftthPtMessages,
	},
}
