import { useContext, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { LayoutProvider, MasterInit } from 'ufinet-web-components'
import { AuthContext, authService } from 'ufinet-web-functions'

const Layout = () => {
	const { setAuthFrame } = useContext(AuthContext)

	const authFrameRef = useRef<HTMLIFrameElement>(null)

	useEffect(() => {
		authFrameRef.current && setAuthFrame(authFrameRef.current)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const NewLayoutProvider = LayoutProvider as any

	return (
		<NewLayoutProvider>
			<Outlet />
			<MasterInit />
			<ToastContainer />
			<iframe ref={authFrameRef} hidden={true} title="Iframe login SSO" src={authService.getSSOLoginUrl()} />
		</NewLayoutProvider>
	)
}

export default Layout
